import Button from "../../GlobalComponents/Button/Button";
import { useEffect, useState, useRef } from "react";
import classes from "./First.module.scss";
import img1 from "../../../assets/img/trading/1/2.webp";
import light from '../../../assets/img/PromotionsPage/Banner/light.webp';
import light_mob from '../../../assets/img/PromotionsPage/Banner/light_mob.webp';
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const First = () => {
  const { t } = useTranslation();
  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const col2 = useRef(null);
  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(col1.current, {
        y: 0,
        opacity: 1,
        scale: 1,
        duration: 1.2,
        delay: 0.3,
      });
      gsap.timeline().to(col2.current, {
        y: 0,
        opacity: 1,
        duration: 1.2,
        delay: 0.3,
      });
    }
  }, [width]);
  return (
    <div className={[classes.first, ""].join(" ")}>
      <div className={[classes.firstBody, ""].join(" ")}>
        <img className={classes.light} src={light} alt='' />
        <img className={classes.light_mob} src={light_mob} alt='' />

        <div className="container">
          <div className={[classes.firstRow, ""].join(" ")}>
            <div className={[classes.firstRowLeft, ""].join(" ")} ref={col2}>
              <h2 className={[classes.firstTitle, "font-64"].join(" ")}>
                {t('conditions.banner.title')}
              </h2>
              <p className={[classes.firstText, "font-20"].join(" ")}>
                {t('conditions.banner.text')}
              </p>
              <div className={[classes.firstBtn, ""].join(" ")}>
                <Button>
                  {t('btn.start_trading')}
                </Button>
              </div>
            </div>
            <div className={[classes.firstRowRight, ""].join(" ")} ref={col1}>
              <img src={img1} alt="Trading Conditions on BGC Brokers" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default First;
