import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Banner from '../components/PromotionsPage/Banner/Banner';
import WelcomeBonus from '../components/PromotionsPage/WelcomeBonus/WelcomeBonus';
import BonusOn from '../components/PromotionsPage/BonusOn/BonusOn';
import BonusOnEach from '../components/PromotionsPage/BonusOnEach/BonusOnEach';
import Table from '../components/PromotionsPage/Table/Table';
import HowToGet from '../components/PromotionsPage/HowToGet/HowToGet';

const PromotionsPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>BGC Brokers · Promotions</title>
        <meta name="title" content="BGC Brokers Promotions - Get the Best Bonuses and Offers Today!" />
        <meta property="og:title" content="BGC Brokers Promotions - Get the Best Bonuses and Offers Today!" />
        <meta property="twitter:title" content="BGC Brokers Promotions - Get the Best Bonuses and Offers Today!" />

        <meta name="description" content="Take advantage of BGC Brokers promotions and bonuses to boost your trading experience. Choose from various offers to make your journey in the financial markets more profitable. Start trading now!" />
        <meta property="og:description" content="Take advantage of BGC Brokers promotions and bonuses to boost your trading experience. Choose from various offers to make your journey in the financial markets more profitable. Start trading now!" />
        <meta property="twitter:description" content="Take advantage of BGC Brokers promotions and bonuses to boost your trading experience. Choose from various offers to make your journey in the financial markets more profitable. Start trading now!" />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <WelcomeBonus />
        <BonusOnEach />
        <BonusOn />
        <Table />
        <HowToGet />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default PromotionsPage;
