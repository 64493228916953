import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Banner from "../components/IpoPage/Banner/Banner";
import Offer from "../components/IpoPage/Offer/Offer";
import Access from "../components/IpoPage/Access/Access";
import Steps from "../components/IpoPage/Steps/Steps";
import Faqs from "../components/IpoPage/Faqs/Faqs";

const IpoPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>BGC Brokers</title>
        <meta name="title" content="Online Trading Platform for Stocks, Cryptocurrencies & More" />
        <meta property="og:title" content="Online Trading Platform for Stocks, Cryptocurrencies & More" />
        <meta property="twitter:title" content="Online Trading Platform for Stocks, Cryptocurrencies & More" />

        <meta name="description" content="BGC Brokers offers an online trading platform for trading stocks, cryptocurrencies, forex, and more. Get access to the global markets and start trading with a reliable and secure broker. Join now and enjoy commission-free trading!" />
        <meta property="og:description" content="BGC Brokers offers an online trading platform for trading stocks, cryptocurrencies, forex, and more. Get access to the global markets and start trading with a reliable and secure broker. Join now and enjoy commission-free trading!" />
        <meta property="twitter:description" content="BGC Brokers offers an online trading platform for trading stocks, cryptocurrencies, forex, and more. Get access to the global markets and start trading with a reliable and secure broker. Join now and enjoy commission-free trading!" />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <Offer />
        <Access />
        <Steps />
        <Faqs />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default IpoPage;
